"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customers = void 0;
var customers = [{
  'id': 1,
  'name': 'Total',
  'logo': './assets/images/customers/Logo-Total_100.png',
  'categories': ['marketing', 'applications'],
  'projects': [{
    'id': 1,
    'name': 'Back to school 2018',
    'project_categories': ['marketing'],
    'files': [{
      'id': 11,
      'url': './assets/images/projects/total/backtoschool/image121-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image121-thumbnail.jpeg'
      }
    }, {
      'id': 13,
      'url': './assets/images/projects/total/backtoschool/image117-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image117-thumbnail.jpeg'
      }
    }, {
      'id': 14,
      'url': './assets/images/projects/total/backtoschool/image118-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image118-thumbnail.jpeg'
      }
    }, {
      'id': 16,
      'url': './assets/images/projects/total/backtoschool/image122-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image122-thumbnail.jpeg'
      }
    }, {
      'id': 17,
      'url': './assets/images/projects/total/backtoschool/image120-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image120-thumbnail.jpeg'
      }
    }, {
      'id': 18,
      'url': './assets/images/projects/total/backtoschool/image123-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image123-thumbnail.jpeg'
      }
    }, {
      'id': 15,
      'url': './assets/images/projects/total/backtoschool/image119-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image119-thumbnail.jpeg'
      }
    }, {
      'id': 1,
      'url': './assets/images/projects/total/backtoschool/image21-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image21-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/total/backtoschool/image22-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image22-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/total/backtoschool/image23-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image23-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/total/backtoschool/image24-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image24-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/total/backtoschool/image28-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image28-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/total/backtoschool/image29-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image29-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/total/backtoschool/image30-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image30-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/total/backtoschool/image31-compressed.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image31-thumbnail.jpeg'
      }
    }, {
      'id': 9,
      'url': './assets/images/projects/total/backtoschool/image33-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image33-thumbnail.jpeg'
      }
    }, {
      'id': 10,
      'url': './assets/images/projects/total/backtoschool/image34-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image34-thumbnail.jpeg'
      }
    }, {
      'id': 12,
      'url': './assets/images/projects/total/backtoschool/image32-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/backtoschool/image32-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 2,
    'name': 'Séance de dedicade avec Sebastien Loeb',
    'project_categories': ['events'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image561-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image561-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image562-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image562-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image563-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image563-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image564-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image564-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image571-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image571-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image572-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image572-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image573-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image573-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image574-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image574-thumbnail.jpeg'
      }
    }, {
      'id': 9,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image581-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image581-thumbnail.jpeg'
      }
    }, {
      'id': 10,
      'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image582-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/seance_dedicade_Sebastien_Loeb_champion_wtcc/image582-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 3,
    'name': 'Roadshow 2019 "Njariñu huile Total"',
    'project_categories': ['marketing'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/total/roadshow/image128-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image128-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/total/roadshow/image124-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image124-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/total/roadshow/image125-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image125-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/total/roadshow/image126-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image126-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/total/roadshow/image127-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image127-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/total/roadshow/image5-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image5-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/total/roadshow/image6-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image6-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/total/roadshow/image10-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image10-thumbnail.jpeg'
      }
    }, {
      'id': 9,
      'url': './assets/images/projects/total/roadshow/image13-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image13-thumbnail.jpeg'
      }
    }, {
      'id': 10,
      'url': './assets/images/projects/total/roadshow/image17-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image17-thumbnail.jpeg'
      }
    }, {
      'id': 11,
      'url': './assets/images/projects/total/roadshow/image20-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image20-thumbnail.jpeg'
      }
    }, {
      'id': 12,
      'url': './assets/images/projects/total/roadshow/image22-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image22-thumbnail.jpeg'
      }
    }, {
      'id': 13,
      'url': './assets/images/projects/total/roadshow/image29-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/roadshow/image29-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 4,
    'name': 'Ouverture de la station Al Quaraouine',
    'project_categories': ['marketing', 'events'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image531-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image531-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image532-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image532-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image533-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image533-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image534-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image534-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image541-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image541-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image542-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image542-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image543-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image543-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image544-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image544-thumbnail.jpeg'
      }
    }, {
      'id': 9,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image551-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image551-thumbnail.jpeg'
      }
    }, {
      'id': 10,
      'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image552-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_total_al_quaraouine/image552-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 5,
    'name': 'Marrakech Grand Prix 2014',
    'project_categories': ['marketing', 'events'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image1-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image1-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image2-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image2-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image3-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image3-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image4-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image4-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image5-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image5-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image6-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image6-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image7-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image7-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image8-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image8-thumbnail.jpeg'
      }
    }, {
      'id': 9,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image9-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image9-thumbnail.jpeg'
      }
    }, {
      'id': 10,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image10-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image10-thumbnail.jpeg'
      }
    }, {
      'id': 11,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image11-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image11-thumbnail.jpeg'
      }
    }, {
      'id': 12,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image12-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image12-thumbnail.jpeg'
      }
    }, {
      'id': 13,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image13-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image13-thumbnail.jpeg'
      }
    }, {
      'id': 14,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image14-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image14-thumbnail.jpeg'
      }
    }, {
      'id': 15,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image15-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image15-thumbnail.jpeg'
      }
    }, {
      'id': 16,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image16-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image16-thumbnail.jpeg'
      }
    }, {
      'id': 17,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image17-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image17-thumbnail.jpeg'
      }
    }, {
      'id': 18,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image18-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image18-thumbnail.jpeg'
      }
    }, {
      'id': 19,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image19-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image19-thumbnail.jpeg'
      }
    }, {
      'id': 20,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image20-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image20-thumbnail.jpeg'
      }
    }, {
      'id': 21,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/21-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/21-thumbnail.jpeg'
      }
    }, {
      'id': 22,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image22-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image22-thumbnail.jpeg'
      }
    }, {
      'id': 23,
      'url': './assets/images/projects/total/marrakech_grand_prix2014/image66-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/marrakech_grand_prix2014/image66-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 6,
    'name': 'Ouverture de la station Ourika',
    'project_categories': ['marketing', 'events'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/total/ouverture_station_ourika/image1-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image1-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/total/ouverture_station_ourika/image2-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image2-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/total/ouverture_station_ourika/image3-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image3-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/total/ouverture_station_ourika/image4-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image4-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/total/ouverture_station_ourika/image5-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image5-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/total/ouverture_station_ourika/image6-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image6-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/total/ouverture_station_ourika/image7-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image7-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/total/ouverture_station_ourika/image8-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image8-thumbnail.jpeg'
      }
    }, {
      'id': 9,
      'url': './assets/images/projects/total/ouverture_station_ourika/image9-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image9-thumbnail.jpeg'
      }
    }, {
      'id': 10,
      'url': './assets/images/projects/total/ouverture_station_ourika/image10-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/total/ouverture_station_ourika/image10-thumbnail.jpeg'
      }
    }]
  }]
}, {
  'id': 2,
  'name': 'Dano',
  'logo': './assets/images/customers/logo_dano.png',
  'categories': ['creations', 'com-supports'],
  'projects': [{
    'id': 1,
    'name': 'Campagne d\'affichage',
    'project_categories': ['creations'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/dano/projet1/image8-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet1/image8-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/dano/projet1/image9-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet1/image9-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/dano/projet1/image10-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet1/image10-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/dano/projet1/image12-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet1/image12-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/dano/projet1/image13-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet1/image13-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/dano/projet1/image14-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet1/image14-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/dano/projet1/image15-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet1/image15-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/dano/projet1/image14-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet1/image14-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 2,
    'name': 'Campagne modern trade',
    'project_categories': ['creations', 'com-supports'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/dano/projet2/image18-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet2/image18-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/dano/projet2/image19-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet2/image19-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/dano/projet2/image20-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/projet2/image20-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 3,
    'name': 'Ramadan 2018',
    'project_categories': ['creations'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/dano/ramadan/image11-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/ramadan/image11-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 4,
    'name': 'Magal 2018',
    'project_categories': ['creations'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/dano/magal2018/image16-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/dano/magal2018/image16-thumbnail.jpeg'
      }
    }]
  }]
}, {
  'id': 3,
  'name': 'Engie',
  'logo': './assets/images/customers/logo_engie.png',
  'categories': ['creations'],
  'projects': [{
    'id': 1,
    'name': 'Brochure',
    'project_categories': ['creations'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/engie/projet1/image37-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/engie/projet1/image37-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/engie/projet1/image36-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/engie/projet1/image36-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/engie/projet1/image38-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/engie/projet1/image38-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/engie/projet1/image35-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/engie/projet1/image35-thumbnail.jpeg'
      }
    }]
  }]
}, {
  'id': 4,
  'name': 'Timitar',
  'logo': './assets/images/customers/logo_timitar.jpg',
  'categories': ['marketing', 'events'],
  'projects': [{
    'id': 1,
    'name': 'Street animation',
    'project_categories': ['marketing', 'events'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/timitar/animation_street/image1-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image1-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/timitar/animation_street/image2-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image2-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/timitar/animation_street/image3-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image3-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/timitar/animation_street/image4-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image4-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/timitar/animation_street/image5-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image5-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/timitar/animation_street/image6-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image6-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/timitar/animation_street/image7-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image7-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/timitar/animation_street/image8-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image8-thumbnail.jpeg'
      }
    }, {
      'id': 9,
      'url': './assets/images/projects/timitar/animation_street/image9-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image9-thumbnail.jpeg'
      }
    }, {
      'id': 10,
      'url': './assets/images/projects/timitar/animation_street/image10-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/timitar/animation_street/image10-thumbnail.jpeg'
      }
    }]
  }]
}, {
  'id': 5,
  'name': 'Afriquia',
  'logo': './assets/images/customers/logo_afriquia.png',
  'categories': ['marketing', 'events'],
  'projects': [{
    'id': 1,
    'name': 'Tournée nationale lub',
    'project_categories': ['marketing', 'events'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/afriquia/tournee_nationale_lub/image1-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/tournee_nationale_lub/image1-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/afriquia/tournee_nationale_lub/image2-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/tournee_nationale_lub/image2-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/afriquia/tournee_nationale_lub/image3-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/tournee_nationale_lub/image3-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/afriquia/tournee_nationale_lub/image4-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/tournee_nationale_lub/image4-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/afriquia/tournee_nationale_lub/image5-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/tournee_nationale_lub/image5-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 2,
    'name': 'Ouverture de la station Ouislane',
    'project_categories': ['marketing', 'events'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image6-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image6-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image7-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image7-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image8-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image8-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image9-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image9-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image10-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image10-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image11-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image11-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image12-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image12-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image13-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image13-thumbnail.jpeg'
      }
    }, {
      'id': 9,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image14-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image14-thumbnail.jpeg'
      }
    }, {
      'id': 10,
      'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image15-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/ouverture_station_ouislane/image15-thumbnail.jpeg'
      }
    }]
  }, {
    'id': 3,
    'name': 'Powermax fun zone',
    'project_categories': ['marketing', 'events'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/afriquia/powermax_funzone/image1-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/powermax_funzone/image1-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/afriquia/powermax_funzone/image2-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/powermax_funzone/image2-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/afriquia/powermax_funzone/image3-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/powermax_funzone/image3-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/afriquia/powermax_funzone/image4-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/powermax_funzone/image4-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/afriquia/powermax_funzone/image5-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/powermax_funzone/image5-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/afriquia/powermax_funzone/image6-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/afriquia/powermax_funzone/image6-thumbnail.jpeg'
      }
    }]
  }]
}, {
  'id': 6,
  'name': 'Atlanta',
  'logo': './assets/images/customers/logo_atlanta.svg',
  'categories': ['marketing', 'events'],
  'projects': [{
    'id': 1,
    'name': 'Campagne marketing',
    'project_categories': ['marketing', 'events'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/atlanta/projet1/image108-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image108-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/atlanta/projet1/image109-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image109-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/atlanta/projet1/image110-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image110-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/atlanta/projet1/image111-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image111-thumbnail.jpeg'
      }
    }, {
      'id': 5,
      'url': './assets/images/projects/atlanta/projet1/image112-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image112-thumbnail.jpeg'
      }
    }, {
      'id': 6,
      'url': './assets/images/projects/atlanta/projet1/image113-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image113-thumbnail.jpeg'
      }
    }, {
      'id': 7,
      'url': './assets/images/projects/atlanta/projet1/image114-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image114-thumbnail.jpeg'
      }
    }, {
      'id': 8,
      'url': './assets/images/projects/atlanta/projet1/image115-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image115-thumbnail.jpeg'
      }
    }, {
      'id': 9,
      'url': './assets/images/projects/atlanta/projet1/image116-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image116-thumbnail.jpeg'
      }
    }, {
      'id': 10,
      'url': './assets/images/projects/atlanta/projet1/image117-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/atlanta/projet1/image117-thumbnail.jpeg'
      }
    }]
  }]
}, {
  'id': 7,
  'name': 'Horus',
  'logo': './assets/images/customers/logo-horus-thumbnail.jpeg',
  'categories': ['surveys'],
  'projects': [{
    'id': 1,
    'name': 'Enquête pour Horus',
    'project_categories': ['surveys'],
    'files': [{
      'id': 1,
      'url': './assets/images/projects/horus/enquete_horus/image1-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/horus/enquete_horus/image1-thumbnail.jpeg'
      }
    }, {
      'id': 2,
      'url': './assets/images/projects/horus/enquete_horus/image2-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/horus/enquete_horus/image2-thumbnail.jpeg'
      }
    }, {
      'id': 3,
      'url': './assets/images/projects/horus/enquete_horus/image3-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/horus/enquete_horus/image3-thumbnail.jpeg'
      }
    }, {
      'id': 4,
      'url': './assets/images/projects/horus/enquete_horus/image4-compressed-filigrane.jpeg',
      'description': '',
      'thumbnail': {
        'url': './assets/images/projects/horus/enquete_horus/image4-thumbnail.jpeg'
      }
    }]
  }]
}];
exports.customers = customers;